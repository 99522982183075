import hljs from '../assets/js/hightlight';
import marked from '../assets/js/marked';
import mermaid from 'mermaid';

hljs.initHighlightingOnLoad();

// 初始化mermaid配置
mermaid.initialize({
  startOnLoad: false,  // 我们会手动控制渲染
  theme: 'default',
  securityLevel: 'loose',
  flowchart: {
    useMaxWidth: true,
    htmlLabels: true
  },
  logLevel: 3  // 修正为正确的日志级别
});

// 将mermaid暴露到window对象上
window.mermaid = mermaid;

const renderer = new marked.Renderer();

// 配置marked
marked.setOptions({
    renderer,
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    highlight: function (code) {
        return hljs.highlightAuto(code).value;
    }
});

// 导出供其他模块使用
export { marked, mermaid };
export default marked;
