<template>
    <div ref="preview">
        <div
            :class="`markdown-preview ${'markdown-theme-' + theme}`"
            v-html="html"
        ></div>
        <!-- 预览图片-->
        <div :class="['preview-img', previewImgModal ? 'active' : '']">
            <span
                class="close icon-close iconfont"
                @click="previewImgModal = false"
            ></span>
            <img :src="previewImgSrc" :class="[previewImgMode]" alt=""/>
        </div>
    </div>
</template>

<script>
    import { marked, loadMermaid } from '../../config/marked';

    export default {
        name: 'markdown-preview',
        props: {
            initialValue: {
                // 初始化内容
                type: String,
                default: ''
            },
            markedOptions: {
                type: Object,
                default: () => ({})
            },
            theme: {
                type: String,
                default: 'light'
            },
            copyCode: {// 复制代码
                type: Boolean,
                default: true
            },
            copyBtnText: {// 复制代码按钮文字
                type: String,
                default: '复制代码'
            }
        },
        data() {
            return {
                html: '',
                previewImgModal: false,
                previewImgSrc: '',
                previewImgMode: ''
            };
        },
        mounted() {
            // 如果使用CDN版本，需要先加载脚本
            if (typeof loadMermaid === 'function') {
                loadMermaid().then(() => {
                    this.translateMarkdown();
                });
            } else {
                this.translateMarkdown();
            }
        },
        methods: {
            translateMarkdown() {
                let html = marked(this.initialValue, {
                    sanitize: false,
                    ...this.markedOptions
                }).replace(/href="/gi, 'target="_blank" href="');
                if (this.copyCode) {
                    html = html.replace(/<pre>/g, '<div class="code-block"><span class="copy-code">' + this.copyBtnText + '</span><pre>').replace(/<\/pre>/g, '</pre></div>');
                }
                
                // 添加对mermaid图表的支持
                html = this.parseMermaid(html);
                
                this.html = html;

                this.addCopyListener();
                this.addImageClickListener();
                this.renderMermaidDiagrams(); // 添加渲染Mermaid图表的方法调用
            },
            addCopyListener() {// 监听复制操作
                setTimeout(() => {
                    const btns = document.querySelectorAll(
                        '.code-block .copy-code'
                    );
                    this.btns = btns;
                    for (let i = 0, len = btns.length; i < len; i++) {
                        btns[i].onclick = () => {
                            const code = btns[i].parentNode.querySelectorAll(
                                'pre'
                            )[0].innerText;
                            const aux = document.createElement('input');
                            aux.setAttribute('value', code);
                            document.body.appendChild(aux);
                            aux.select();
                            document.execCommand('copy');
                            document.body.removeChild(aux);
                            this.$emit('on-copy', code);
                        };
                    }
                }, 600);
            },
            addImageClickListener() {// 监听查看大图
                const {imgs = []} = this;
                if (imgs.length > 0) {
                    for (let i = 0, len = imgs.length; i < len; i++) {
                        imgs[i].onclick = null;
                    }
                }
                setTimeout(() => {
                    this.imgs = this.$refs.preview.querySelectorAll('img');
                    for (let i = 0, len = this.imgs.length; i < len; i++) {
                        this.imgs[i].onclick = () => {
                            const src = this.imgs[i].getAttribute('src');
                            this.previewImage(src);
                        };
                    }
                }, 600);
            },
            previewImage(src) {// 预览图片
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    if (height / width > 1.4) {
                        this.previewImgMode = 'horizontal';
                    } else {
                        this.previewImgMode = 'vertical';
                    }
                    this.previewImgSrc = src;
                    this.previewImgModal = true;
                };
            },
            parseMermaid(html) {
                // 添加更健壮的调试信息
                const replacedHtml = html.replace(/<pre><code class="language-mermaid">([\s\S]*?)<\/code><\/pre>/gi, (match, code) => {
                    try {
                        // 预处理代码，去除可能的HTML实体编码
                        const decodedCode = code
                            .replace(/&lt;/g, '<')
                            .replace(/&gt;/g, '>')
                            .replace(/&amp;/g, '&')
                            .trim();
                        
                        console.log('Mermaid代码:', decodedCode);
                        
                        // 尝试验证Mermaid语法
                        if (!decodedCode.startsWith('graph') && 
                            !decodedCode.startsWith('sequenceDiagram') && 
                            !decodedCode.startsWith('classDiagram') && 
                            !decodedCode.startsWith('flowchart')) {
                            console.warn('Mermaid语法可能不正确，应以graph/flowchart/sequenceDiagram等关键字开始');
                        }
                        
                        return `<div class="mermaid-diagram"><div class="mermaid">${decodedCode}</div></div>`;
                    } catch (e) {
                        console.error('处理Mermaid代码时出错:', e);
                        return match; // 出错时保留原始代码块
                    }
                });
                return replacedHtml;
            },
            renderMermaidDiagrams() {
                this.$nextTick(() => {
                    if (window.mermaid) {
                        try {
                            const diagrams = document.querySelectorAll('.mermaid');
                            console.log(`找到${diagrams.length}个Mermaid图表`);
                            
                            if (diagrams.length === 0) return;
                            
                            // 重新初始化Mermaid配置
                            window.mermaid.initialize({
                                startOnLoad: false,
                                theme: 'default',
                                logLevel: 4, // 详细日志
                                securityLevel: 'loose',
                                flowchart: {
                                    useMaxWidth: true,
                                    htmlLabels: true,
                                    curve: 'basis'
                                },
                                er: {
                                    useMaxWidth: true
                                },
                                sequence: {
                                    useMaxWidth: true,
                                    showSequenceNumbers: false
                                }
                            });
                            
                            // 逐个渲染图表
                            diagrams.forEach((diagram, index) => {
                                try {
                                    const content = diagram.textContent.trim();
                                    console.log(`渲染第${index+1}个图表:`, content.slice(0, 50) + '...');
                                    window.mermaid.render(`mermaid-${Date.now()}-${index}`, content, (svgCode) => {
                                        diagram.innerHTML = svgCode;
                                    });
                                } catch (err) {
                                    console.error(`渲染第${index+1}个图表失败:`, err);
                                }
                            });
                        } catch (e) {
                            console.error('Mermaid渲染错误:', e);
                        }
                    } else {
                        console.warn('Mermaid库未加载');
                    }
                });
            }
        },
        watch: {
            initialValue() {
                this.translateMarkdown();
            }
        }
    };
</script>

<style scoped lang="less">
    @import "../../assets/font/iconfont.css";
    @import "../../assets/css/theme";
    @import "../../assets/css/index";
    @import "../../assets/css/light";
    @import "../../assets/css/dark";
    @import "../../assets/css/one-dark";
    @import "../../assets/css/github";
</style>
